import Glide from '@glidejs/glide';

const sliders = document.querySelectorAll('.glide');
let timePlay = false;

if (sliders) {
    for (let i = 0; i < sliders.length; i++) {
        if (sliders[i].classList.contains('hero-glide')) {
            timePlay = 4000;
        } else {
            timePlay = false;
        }

        let glide = new Glide(sliders[i], {
            //gap: 15,
            type: 'slider',
            rewind: true,
            focusAt: 'center',
            autoplay: timePlay,
        });

        // Automated height on Carousel build
        glide.on('build.after', function () {
            glideHandleHeight();
        });

        // Automated height on Carousel change
        glide.on('run.after', function () {
            glideHandleHeight();
        });

        // Mount!
        glide.mount({
        });

        // Resize height
        function glideHandleHeight() {
            const activeSlide = document.querySelector('.glide__slide--active');
            const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;

            const glideTrack = document.querySelector('.glide__track');
            const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;require('./afspraaksysteem');

            if (activeSlideHeight !== glideTrackHeight) {
                glideTrack.style.height = `${activeSlideHeight}px`;
            }
        }

        glide.on('resize', function() {
            glide.update();
        });

        /*** Actions Property gallery ***/

        if (document.querySelector('.btn-zoom-in') && document.querySelector('.btn-zoom-out')) {
            let openfullScreen = document.querySelector('.btn-zoom-in');
            let closefullScreen = document.querySelector('.btn-zoom-out');

            openfullScreen.addEventListener('click', function () {
                document.querySelector('.property-gallery').classList.add('full-screen');
                glide.update();
            });

            closefullScreen.addEventListener('click', function () {
                document.querySelector('.property-gallery').classList.remove('full-screen');
                glide.update();
            });
        }

        if (document.querySelector('.floor-plan-zoom-in') && document.querySelector('.floor-plan-zoom-out')) {
            let openfullScreen = document.querySelector('.floor-plan-zoom-in');
            let closefullScreen = document.querySelector('.floor-plan-zoom-out');

            openfullScreen.addEventListener('click', function () {
                document.querySelector('.property-floor-plan').classList.add('full-screen');
                glide.update();
            });

            closefullScreen.addEventListener('click', function () {
                document.querySelector('.property-floor-plan').classList.remove('full-screen');
                glide.update();
            });
        }

    }
}
