/* <![CDATA[ */


function initQuiestionnaireWithLocale(locale) {
	locale = locale || "NL";

	ga = _gaq = undefined;

	if (jQuery('#afspraak-koppeling').length > 0) {
		var koppelcode = jQuery('#afspraak-koppeling #koppelcode').text(),
			praktijk = jQuery('#afspraak-koppeling #praktijknaam').text();
	} else {
		var koppelcode = 'Eafspraak-919',
			praktijk = 'Huisartsen Kop van Zuid';
	}

	initQuestionnaire({
		id:"ea0acPhq",
		containerId:"questionnaire",
		publisherUri:"https://aanmelden.mijngezondheid.net/publisher",
		initAnswersId:"",
		//theme:"Sparreboom afspraak",
		locale: locale,
		clearForm: true,
		//googleAnalyticsNamespace: "pharmapartners",
		//debugMode: true,
		initAnswers : {
			"websiteID" : koppelcode,
			"practiceName" : praktijk,
			"registerUrl" : ""
	    },
		events: {
			submit_error: function(resp,message) {
				var h = [];
				h.push('<div id="formsEngine_submit_error_msg" class="ws-errorbox" style="display: block; text-align: center; margin-bottom: 15px; font-size: 1.3em;">');
					h.push('<p class="help-block">'+message+'</p>');
				h.push('</div>');
				
				$('#questionnaire .questionnaire form footer').before(h.join(''));
				$('#questionnaire .questionnaire form footer .popupbutton').show().removeClass('disabled');
				
				$('#wizard-afspraak-submit-loading').remove();
				$('#questionnaire .page fieldset > div.contentblock').show();
			},
			submit_success: function(resp,message) {
				var registerUrl = $('#questionnaire').questionnaire("findAnswer","registerUrl","");
				if (registerUrl != "") {registerUrl = '<p class="information-text">Heeft u nog geen MijnGezondheid.net, meld u dan nu aan.</p><a href=\"' + registerUrl + '" target="_blank">Ik wil me aanmelden</a>'}
				
				$('#questionnaire div.questionnaire').html(
					message.f(
						window['formsEngine_TimeslotDateFormatted'],
						window['formsEngine_TimeslotTimeFormatted'],
						window['formsEngine_EmployeeName'],
						registerUrl
					)
				);
			},
			render: function() {
				var thisForm = this.getQuestionnaire();
				if(thisForm.renderCtx.data.currentPageNumber == 1) {
					var footer = $('#afspraak_page1>footer');
					footer.find('a').appendTo($('#afspraak_first_page_form'));
					footer.remove();

					var patientChkbxLabel = $('#patient_checkbox span');
					patientChkbxLabel.html(patientChkbxLabel.html() + ' ' + this.options.initAnswers.practiceName);
				}
				
				var submitBtn = $('#formsEngine-wizard-afspraak-submit-btn');
				if(submitBtn.length) {
					submitBtn.off('click').on('click',function() {
					
						$(this).hide();
						
						$('#formsEngine_submit_error_msg').remove();
						$('#questionnaire .page fieldset > div.contentblock').hide();
						
						var h = [];
						h.push('<div id="wizard-afspraak-submit-loading" class="spinner rectangle-bounce">');
							h.push('<div class="rect1"></div>');
							h.push('<div class="rect2"></div>');
							h.push('<div class="rect3"></div>');
							h.push('<div class="rect4"></div>');
							h.push('<div class="rect5"></div>');
						h.push('</div>');
						$('#questionnaire .page fieldset').append(h.join(''));
					});
				}
			}
		}
	});

	String.prototype.f = function () {
		var args = arguments;
		return this.replace(/\{\{|\}\}|\{(\d+)\}/g, function (m, n) {
			if (m == "{{") { return "{"; }
			if (m == "}}") { return "}"; }
			return args[n];
		});
	};

}

jQuery(document).ready(function($) {
	$('#select-theme').on('change',function() {
		$('#theme-css').attr('href',$(this).val());
	});
	$('#select-language').on('change', function() {
		$('#questionnaire').questionnaire('option','locale',$(this).val());
		$('#questionnaire').questionnaire('initEvent');
	});
	initQuiestionnaireWithLocale('NL');
});

$('.afspraakbutton, .online-appointment-widget').on('click', function() {
	show_explanation( function() {
		$('#afspraak_maken_container').css('display', 'block').animate({opacity: 1});
		$('.main-nav').removeClass('d-block');
		$('body').removeClass('overflow-hidden');
	});
});

$('body').on('click', function(e) {
	if ($('#afspraak_maken_container').is(":visible")) {
		if ($(e.target).attr('id') === 'afspraak_maken_container' || $(e.target).attr('id') === 'close_afspraak') {
			$('#afspraak_maken_container').animate({opacity: 0}, function() {
				$(this).css('display', 'none');
			});
		}
	}
	if ($('#system_explanation').is(":visible")) {
		if ($(e.target).attr('id') === 'system_explanation' || $(e.target).attr('id') === 'close_explanation') {
			$('#system_explanation').animate({opacity: 0}, function() {
				$(this).css('display', 'none');
			});
		}
	}
});

function show_explanation(callback) {
	if (jQuery('#system_explanation').hasClass('noshow') === false) {
		jQuery('#system_explanation').css('display', 'block').animate({opacity: 1});
		jQuery('#accept_explanation').on('click', function() {
			jQuery('#system_explanation').animate({opacity: 0}, function() {
				jQuery(this).css('display', 'none');
			});
			callback();
		});
	} else {
		callback();
	}
}



/* ]]> */